/**
 * @generated SignedSource<<785e6dd5650cde84a3c2ec0f9a0c1609>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventVisibility = "LISTED" | "UNLISTED" | "%future added value";
export type ImageContentType = "GIF" | "JPEG" | "PNG" | "WEBP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EventItemEventCardPhoto_event$data = {
  readonly coverPhoto: {
    readonly contentType: ImageContentType;
    readonly rowId: String;
  } | null | undefined;
  readonly name: string;
  readonly prettyUrl: string;
  readonly visibility: EventVisibility;
  readonly " $fragmentType": "EventItemEventCardPhoto_event";
};
export type EventItemEventCardPhoto_event$key = {
  readonly " $data"?: EventItemEventCardPhoto_event$data;
  readonly " $fragmentSpreads": FragmentRefs<"EventItemEventCardPhoto_event">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EventItemEventCardPhoto_event",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prettyUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visibility",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "coverPhoto",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contentType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};

(node as any).hash = "f599e614d6334a8fdf4e99f08df733dc";

export default node;
